import {useUserStore} from '@/stores/user';

export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = useUserStore();
    const phone = userStore.getPhone;

    if (!phone) {
        return navigateTo({
            path: '/phone-validation',
            query: to.query,
        });
    }

});
